import React from "react"
import { Container, Row, Col, Button, Card } from "react-bootstrap"
import Title from "../title"
import * as styles from "../../styles/components/Locations/locations.module.scss"
import Jumbotron from "../Homepage/jumbotron"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby-plugin-react-intl"
import { useIntl } from "gatsby-plugin-react-intl"

import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api"
import { node } from "prop-types"

export default function Locations() {
  const data = useStaticQuery(
    graphql`
      query {
        allContentfulLocation(sort: { fields: location }) {
          edges {
            node {
              slug
              location
              address {
                address
              }
              appointmentLink
              coordinates
              telehealth
              urgentCareHours
            }
          }
        }
      }
    `
  )

  const containerStyle = {
    width: "400px",
    height: "400px",
  }

  const intl = useIntl()

  const locations = data.allContentfulLocation.edges
  return (
    <div className={styles.wrapper}>
      {/* Container 1 */}
      <Title title={intl.formatMessage({ id: "ChooseLocation" })} />

      {/* Container 3 */}
      {/* <Jumbotron variant={2} /> */}

      <Container className="">
        <Row md={3} className="my-5 g-4">
          {locations.map(({ node }, index) => {
            const lat = parseFloat(node.coordinates.split(",")[0])
            const lng = parseFloat(node.coordinates.split(",")[1])
            const coordinates = { lat, lng }

            return (
              <Col key={index}>
                <Card className={styles.card}>
                  {/* Google Map */}
                  <div className="mx-auto">
                    <LoadScript
                      googleMapsApiKey={process.env.GATSBY_GOOGLE_MAPS_API_KEY}
                    >
                      <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={coordinates}
                        zoom={17}
                      >
                        {/* Child components, such as markers, info windows, etc. */}
                        <>
                          <Marker position={coordinates} />
                        </>
                      </GoogleMap>
                    </LoadScript>
                  </div>

                  <Card.Body
                    className={` ${styles.body} text-center d-flex flex-column`}
                  >
                    <h2 className={styles.cardTitle}>{node.location}</h2>
                    <p className={styles.text}>
                      {node.address.address.split("\n")[0]}
                      <br />
                      {node.address.address.split("\n")[1]}
                    </p>

                    <Row lg={2} className={styles.buttons}>
                      <Col>
                        <a href={`${node.appointmentLink}`} target="_blank">
                          <Button size="lg" variant="tertiary" className="me-4">
                            {intl.formatMessage({ id: "bookAppointment" })}
                          </Button>
                        </a>
                      </Col>
                      <Col>
                        <a href={`${node.appointmentLink}`}></a>
                        <Link to={`/locations/${node.slug}`}>
                          <Button>
                            {intl.formatMessage({ id: "moreInfo" })}
                          </Button>
                        </Link>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            )
          })}
        </Row>
      </Container>
    </div>
  )
}
